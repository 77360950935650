
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
import moment from "moment";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      dialogFormVisible2: false,
      title: "",
      total: 0, // 总条目数
      billingNodeList:[],
      form: {
        planDate:"",
      },
      selectform: {
        Phone: "",
        Status: null,
        PageIndex: 1,
        PageSize: 10,
      },
      editForm:{
        planDate: ""
      },
      tousuDialogVisible: false,
    });
    // const selectOperateChange = (item: any) => {
    //   // console.log("label");
    //   // console.log(item);
    //     data.OperateValue = parseInt(item.value);
    //     data.OperateName = item.label;
    //     console.log(data.OperateName);
        
    // };

    // const changePage = ({ pageSize, currentPage }: any) => {
    //   data.query.page = currentPage;
    //   data.query.limit = pageSize;
    //   getBillingNodeList();
    // };

    // const selectTouSu = (item: any) => {   
    //   getBillingNodeList();
    // };

    // const handleSizeChange = (val: any) => {
    //   data.selectform.PageSize = val;
    //   getBillingNodeList();  
    // };
    // const handleCurrentChange = (val: any) => {
    //   data.selectform.PageIndex = val;
    //   getBillingNodeList();  
    // };
    const selectBillingNode = () => {  
      getBillingNodeList();
    };

    const editBillingNode = () => { 
        // console.log(data.editForm.planDate);
        if(data.editForm.planDate == ""){
            Api.Msg.warning("请选择日期时间");
            return;
        }
        let year = parseInt(data.editForm.planDate.substr(0, 4));
        let month = parseInt(data.editForm.planDate.substr(5, 2));
        // let sss = data.editForm.planDate
        // console.log(year + "---" + month);
        // console.log(year + "---" + month + ":::" + data.editForm.planDate);
        let dateData = {Year: year, Month: month, BillingAt: data.editForm.planDate}
        console.log(dateData);
      Api.Config.setBillingNode(dateData).then((res: any) => {
        console.log("res");
        console.log(res);
        
        if (res.Code == 0) {  
            Api.Msg.success("计费节点确认完成");
            data.dialogFormVisible2 = false;
            getBillingNodeList();
        } 
        else{
            Api.Msg.warning(res.ResultMsg);
            return;
        }
      }); 
      getBillingNodeList();
    };
    const getBillingNodeList = () => {
      //console.log(data.query);
        let now = new Date();
        let year = now.getFullYear();
        if(data.form.planDate == ""){
            data.form.planDate = year;
        }
      data.billingNodeList = [];
      let billingnodedata = {Year: data.form.planDate}
      console.log(billingnodedata);
      Api.Config.GetBillingNodeList(billingnodedata).then((res: any) => {
        console.log("res");
        console.log(res);
        
        if (res.Code == 0) { 
            for(var i = 0; i < res.Data.length; i++){
                res.Data[i].BillingAt = res.Data[i].BillingAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                if(res.Data[i].Status == true){
                    res.Data[i].Status = "已确认"
                }
                else{
                    res.Data[i].Status = "未确认"
                }
                data.billingNodeList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
            
            // console.log("2");
            
        } 
      });
            console.log(data.billingNodeList);
    };
    
    const submitBillingNode = (row: any) => {
        var time = moment(new Date(row.BillingAt)).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        data.editForm.planDate = time;
        // console.log("data.editForm.planDate");
        // console.log(time);
        data.dialogFormVisible2 = true;
    };

    // const ChaKanTouSuRef = ref<InstanceType<typeof ChaKanTouSu>>();
    // const SetTouSu = (row: any) => {
    //   data.tousuDialogVisible = true;
    //   let tousuRow = {phone: row.Phone, packagesId: row.PackagesId}
    //   // console.log("tousuRow");
    //   // console.log(row);
    //   ChaKanTouSuRef.value?.searchChange(tousuRow);
    // };
    onMounted(() => {
        getBillingNodeList();
    });

    return {
      ...toRefs(data),
      getBillingNodeList,      
      submitBillingNode,
      selectBillingNode,
      editBillingNode,
    //   selectTouSu,
    //   handleSizeChange,
    //   handleCurrentChange,
    //   viewRefundVoucher,
    //   SetTouSu,
    //   ChaKanTouSuRef,
    //   changePage,
    //   selectOperateChange,
    };
  },
});
